.order-detail {
	&__content {
		flex: 1;
		min-width: 0;
	}

	.expand {
		width: 390px;
		@media (min-width: 992px) {
			width: 300px;
		}
		@media (min-width: 1430px) {
			width: 390px;
		}

		display: flex;
		flex-direction: column;

		.content {
			flex: 1;
			overflow: auto;

			display: flex;
			flex-direction: column;

			&::-webkit-scrollbar-track{
				-webkit-box-shadow: inset 0 0 6px #FFFFFF;
				background-color: #FFFFFF;
			}

			&::-webkit-scrollbar{
				width: 4px;
				background-color: #FFFFFF;
			}

			&::-webkit-scrollbar-thumb{
				background-color: #D9D9D9;
				border-radius: 9px;
			}
		}

		.messages {
			flex: 1;

			.scroll-down{
				padding: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #3598FF;
				border-radius: 50%;
				box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
				height: 32px;
				width: 32px;

				position: sticky;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);
				cursor: pointer;
				z-index: 2;
			}
		}

	}

	.header{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px;
		padding-bottom: 18px;
		border-bottom: 0.5px solid #EFEFEF;

		p{
			margin: 0;
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			font-family: 'Poppins', sans-serif;
			text-transform: capitalize;
		}

		.list-icon {
			display: flex;
			gap: 16px;
			align-items: center;
		}
	}
}
