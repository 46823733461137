.profile {
	&__bg{
		background-color: rgb(0 0 0 / 10%);
	}
	&__avatar {
		max-width: 100%;
	}
	&__img{
		width: 12.5rem;
		height: 12.5rem;
		object-fit: contain;
	}
	&__upload {
		width: 12.5rem;
		height: 12.5rem;
		position: relative;
		&:hover {
			.profile__label {
				background-color: rgb(0 0 0 / 48%);
				transform: scale(1);
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-ms-transform: scale(1);
				-o-transform: scale(1);
				transition-duration: 0.3s;
			}
		}
	}
	&__input {
		opacity: 0;
		left: 0;
		top: 0;
		width: 100%;
		position: absolute;
	}
	&__label {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: scale(0);
		-webkit-transform: scale(0);
		-moz-transform: scale(0);
		-ms-transform: scale(0);
		-o-transform: scale(0);
		i {
			font-size: 28px !important;
			color: #000;
		}
	}
}
