@font-face {
	font-family: "themify";
	src: url("../fonts/themify.eot?-fvbane");
	src: url("../fonts/themify.eot?#iefix-fvbane") format("embedded-opentype"),
		url("../fonts/themify.woff?-fvbane") format("woff"), url("../fonts//themify.ttf?-fvbane") format("truetype"),
		url("../fonts/themify.svg?-fvbane#themify") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="ti-"],
[class*=" ti-"] {
	font-family: "themify";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	*margin-right: 0.3em;
}

[class^="ti-"]:before,
[class*=" ti-"]:before {
	text-decoration: inherit;
	display: inline-block;
	speak: none;
}
.ti-bell:before {
	content: "\E6B8";
}
.ti-user:before {
	content: "\E602";
}
.ti-power-off:before {
	content: "\E682";
}
.error-msg {
	font-size: 13px;
	color: red;
}
.ti-file:before {
	content: "\E6A4";
}
