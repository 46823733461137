html{
	--colorToastr: rgba(44, 47, 50, 1);
	--bgToastr: rgba(252, 252, 252, 1);
	--boxshadowToastr: 0px 2px 40px -12px rgba(0, 0, 0, 0.35);
}


.toastr{
	position: fixed;
	bottom: 6%;
	right: 5.6rem;
	width: 293px;
	z-index: 999;
	@media (max-width: 768px) {
		width: 90%;
		max-width: 293px;
		right: 1.5rem;
		bottom: 1.5rem;
		
	}
	&__exit{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0.8rem;
	}
	&__txt{
		padding: 0 4.2rem 0 5.5rem;
		position: relative;
		font-size: 12px;
	}
	&__item{
		margin-bottom: 15px;
		padding: 1.2rem 0;
		color: var(--colorToastr);
		background-color: var(--bgToastr);
		box-shadow: var(--boxshadowToastr);
		min-height: 60px;
		display: flex;
		align-items: center;
		position: relative;
		animation: 0.3s ease 0s 1 normal none running fadeInRight;
		
		&:before{
			content: '';
			position: absolute;
			left: 1rem;
			width: 3px;
			border-radius: 1.5px;
			bottom: 1.2rem;
			height: calc(100% - 2.4rem)
		}
		background: var(--bgToastr) 2.3rem center url('../../assets/images/toastr-success.svg') no-repeat;
		&:before{
			background: #20A779;
		}
		&--error{
			background: var(--bgToastr) 2.3rem center url('../../assets/images/toastr-delete.svg') no-repeat;
			&:before{
				background: #E2574C;
			}
		}
		&--warning{
			background: var(--bgToastr) 2.3rem center url('../../assets/images/toastr-warning.svg') no-repeat;
			&:before{
				background: #FAC550;
			}
		}
		
	}
}

.c-icon--small{
	width: 2.4rem;
	height: 2.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: none;
	padding: 0;
}

@-webkit-keyframes fadeInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
}
@keyframes fadeInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
}
