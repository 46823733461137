.order-history {
    background-color: #f2f3f5;
}
.order-history-item {
    &__bg {
        padding: 3px 10px;
    }
    &__ttl {
        padding: 12px;
    }
    &__info {
        font-family: var(--font-ubunty);
        font-size: 12px;
    }
}
.activity-info {
    border-top: 1px solid rgba(0, 0, 0, 0.0625);
    padding: 22px 0 12px 0;
    &__statistic {
        min-height: 82px;
        border-top: 1px solid rgba(0, 0, 0, 0.0625);
    }
    &__declined {
        padding: 10px;
        background-color: #ffebee;
    }
    .declined-item {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
.order-changed {
    &__item {
        .icon {
            vertical-align: middle;
        }
    }
    &__status {
        border-radius: 5px;
        display: inline-block;
        &--old {
            color: red;
            text-decoration: line-through;
            font-style: italic;
            background-color: #fdd9d7;
            border-radius: 5px;
        }
        &--new {
            color: blue;
            background-color: #ccf2f6;
            font-weight: bold;
        }
    }
}


.message-history {
    .info {
        padding: 0px 35px 0px 44px;

        display: flex;
        gap: 16px;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .sender-name{
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                text-align: left;

                color: #868686;

            }

            .msg-wrapper {

                max-width: 832px;
                width: 100%;

                .msg {
                    width: fit-content;
                    max-width: 100%;
                    color: #3A3A3A;
                    background: #F4F4F4;
                    transition: .2s;
                    border: 1px solid transparent;
                    cursor: pointer;
                    border-radius: 6px;
                    padding: 8px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: left;

                    &:hover{
                        border: 1px solid #7B87FF;
                    }

                    &.owner {
                        background: #F4F5FF;
                    }

                    p{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        margin: 0;
                    }
                }

            }
        }
    }
}