.c-pagination {
	display: flex;
	margin-top: 1.625rem;
	&__list {
		display: flex;
	}
	&__link {
		font-weight: 600;
		width: 2.5rem;
		height: 2.1875rem;
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f6f8fa;
		border: 1px solid rgba(0, 0, 0, 0.0625);
		margin: 0 0.3125rem;
		border-radius: 4px;
		color: #516177;
		text-decoration: none;
		i {
			font-weight: bold;
		}
		&:hover,
		&.active {
			border-color: #72777a;
			color: #fff;
			background-color: #72777a;
			text-decoration: none;
		}
		&:hover {
			&.active {
				cursor: not-allowed;
			}
		}
	}
	&__disabled-btn {
		opacity: 0.6;
		a {
			cursor: not-allowed;
			&:hover {
				background: #f6f8fa;
				border-color: rgba(0, 0, 0, 0.0625);
				color: #516177;
			}
		}
	}
	li.break {
		pointer-events: none;
	}
}
