.c-textarea {
	padding: 12px;
	font-family: var(--font-ubunty);
	&::placeholder {
		color: #cecece;
	}
	&:focus {
		outline: none;
	}
}
