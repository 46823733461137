.chat-form {
  padding: 20px;
  display: flex;
  gap: 12px;
  align-items: center;


  .input{

    flex: 1;
    max-height: 110px;
    min-height: 40px;
    height: fit-content;
    //padding: 8px;
    border: 1px solid #F4F4F4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    transition: .2s;
    overflow: hidden;

    &.focus {
      border-color: rgba(134, 183, 254, 0.25);
    }

    textarea{
      width: 100%;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      line-height: 21px;
      color: #3D3D3D;
      padding: 8px;
      height: 100%;
      max-height: calc(110px - 16px);

      outline: none;
      border: none;
      resize: none;

      transition: .2s;

      &::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 6px #FFFFFF;
        background-color: #FFFFFF;
      }

      &::-webkit-scrollbar
      {
        width: 4px;
        background-color: #FFFFFF;
      }

      &::-webkit-scrollbar-thumb
      {
        background-color: #D9D9D9;
        border-radius: 9px;
      }
    }
  }

  .btn-send{
    transition: .2s;
    &.active{
      padding: 6px;
      border-radius: 50%;
      background-color: #0D6EFD;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: .2s;
      align-self: flex-end;
    }

    img {
      transition: .2s;

    }
  }
}