.header-icon {
  padding: 4px;
  border-radius: 4px;

  &.active {
    background-color: rgba(134, 183, 254, 0.15);
  }

  &.cursor {
    cursor: pointer;
  }
}