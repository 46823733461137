.order-status {
	font-size: 75%;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	text-transform: capitalize;
	background-color: #ddd;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	padding: 3px 10px;
	font-weight: 700;
	&--editing, 
	&--pending {
		color: var(--color-yellow);
		background-color: var(--bg-yellow);
	}
	&--preparing, 
	&--approved{
		color: var(--color-green);
		background-color: var(--bg-green);
	}
	&--available {
		color: var(--color-blue);
		background-color: var(--bg-blue);
	}
	&--completed {
		color: var(--color-purple);
		background-color: var(--bg-purple);
	}
	&--finalizing {
		color: #020202 !important;
		background-color: #fbc02d !important;
	}
	&--canceled, 
	&--declined {
		color: #d32f2f !important;
		background-color: #ffebee !important;
	}
	&--disputing {
		color: #8D28DD !important;
		background-color: #F6EAFF!important;
	}
	&--refunded {
		color: #FF6B00 !important;
		background-color: #FFF1E7 !important;
	}
}

.order-type{
	text-transform: lowercase;
	display: inline-block;
	font-size: 12px;
	font-weight: 700;
	text-align: center;
	vertical-align: initial;
	white-space: nowrap;
	padding: 4px 8px;
	margin: 0;
	border-radius: 50rem;

	&__writing {
		background: rgba(251, 192, 45, 0.05);
		color: rgba(251, 192, 45, 1) !important;
	}

	&__editing{
		background: rgba(124, 106, 236, 0.05);
		color: rgba(124, 106, 236, 1) !important;
	}
}