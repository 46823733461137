.uid {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  &--editing,
  &--pending {
    color: var(--color-yellow);
  }
  &--preparing,
  &--approved{
    color: var(--color-green);
  }
  &--available {
    color: var(--color-blue);
  }
  &--completed {
    color: var(--color-purple);
  }
  &--finalizing {
    color: #020202 !important;
  }
  &--canceled,
  &--declined {
    color: #d32f2f !important;
  }
  &--disputing {
    color: #8D28DD !important;
  }
  &--refunded {
    color: #FF6B00 !important;
  }
}

.group-uid{
  display: flex;
  align-items: flex-start;

  .uid{
    flex: 1;
  }

  .icon-redirect {
    visibility: hidden;
    opacity: 0;

    transition: .2s;
  }
}

.message {
  &__box {
    width: 0px;
    &.show {
      width: 390px;
      transition: .25s;
      height: calc(100vh - 82px);

      display: flex;
      flex-direction: column;
      margin-left: 8px;

    }
    transition: .25s;
    background-color: #ffffff;

    .header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
      padding-bottom: 18px;
      border-bottom: 0.5px solid #EFEFEF;

      p{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        font-family: 'Poppins', sans-serif;

        span {
          cursor: pointer;
        }
      }

      .list-icon {
        display: flex;
        gap: 16px;
        align-items: center;
      }
    }

    .messages{
      flex: 1;
      overflow: auto;
      position: relative;

      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px #FFFFFF;
        background-color: #FFFFFF;
      }

      &::-webkit-scrollbar{
        width: 4px;
        background-color: #FFFFFF;
      }

     &::-webkit-scrollbar-thumb{
        background-color: #D9D9D9;
       border-radius: 9px;
      }
    }
  }

  &__btn {
    height: 45px;
    width: 45px;
    text-align: center;
    line-height: 45px;
    position: relative;
    border: none;
    background: transparent;
  }
  &__total {
    position: absolute;
    right: 6px;
    top: 3px;
    background-color: #ff3c7e;
    border-radius: 50px;
    color: #fff;
    font-size: 10px;
    line-height: 1;
    padding: 3px 5.5px;
  }
}

.message-item{
  padding: 12px 20px;

  display: flex;
  gap: 8px;
  align-items: center;

  background: #FFF;
  cursor: pointer;

  transition: all .2s;

  &:hover{
    background: #F9FAFB;

    .txt, .time {
      color: #0A58CA !important;
    }

    .icon-redirect{
      transition: .2s;
      visibility: visible;
      opacity: 1;
    }
  }


  .content{
    display: flex;
    flex-direction: column;
    gap: 8px;



    .msg{
      display: flex;
      gap: 12px;
      align-items: flex-end;
      .txt{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 205px;

        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        line-height: 21px;
        color: #72777A;

        &--unread{
          color: #3A3A3A;
          font-weight: 500;
        }
      }


      .time{
        width: 85px;
        text-align: right;

        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        line-height: 15px;
        color: #72777A;
        text-transform: uppercase;

      }
    }
  }
}

.chat-message {
  padding: 0px 20px;
  padding-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  overflow: auto;
  position: relative;
  &::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px #FFFFFF;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar{
    width: 4px;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar-thumb{
    background-color: #D9D9D9;
    border-radius: 9px;
  }
}

.chat-group{
  display: flex;
  gap: 16px;
  align-items: flex-start;

  .chat {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;

    .sender-name {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #72777A;
    }

    .chat-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}


.avatar {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;

  img{
    border-radius: 50%;
  }

  .badge {
    position: absolute;
    right: -3px;
    top: -4px;

    width: 16px;
    height: 16px;

    color: #FFFFFF;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    background-color: #F83F7D;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

  }
}


.chat-item-wrapper{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chat-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .msg{
    max-width: 221px;
    padding: 8px;
    border-radius: 6px;

    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3A3A3A;
    background: #F4F4F4;
    border: 1px solid transparent;
    cursor: pointer;
    white-space: pre-wrap;

    transition: .2s;

    overflow-wrap: break-word;


    &.owner{
      background: #F4F5FF;
    }

    &:hover, &.highlight{
      border: 1px solid #7B87FF;
    }
  }

  .time {
    text-align: right;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    color: #C0C0C0;
    text-transform: uppercase;
  }
}

.box-message {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  //gap: 16px;
  .group-time-message {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;

    .same-sender{
      margin-top: -8px;
    }
  }
  .scroll-down{
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3598FF;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    height: 32px;
    width: 32px;

    position: sticky;
    bottom: 10px;
    left: 50%;
    cursor: pointer;
    z-index: 2;
  }

  .number-latest-message {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 19px;
    font-weight: 500;
    background: #3598FF;
    padding: 4px 16px;
    width: fit-content;
    border-radius: 16px;
    color: #FFF;

    position: sticky;
    left: 50%;
    cursor: pointer;
    z-index: 2;
    bottom: 0px;
    transform: translateX(-50%);
  }

}

.message-error{
  display: flex;
  gap: 4px;
  align-items: center;

  .txt-err {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;

    color: #72777A;

    span {
      text-decoration: underline;
      color: #0D6EFD;
      display: inline-block;
      cursor: pointer;
    }

  }
}

.message-notification {
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px #FFFFFF;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar{
    width: 4px;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar-thumb{
    background-color: #D9D9D9;
    border-radius: 9px;
  }
}