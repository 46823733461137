.notification-item {
  &__pill {
    font-size: 11px !important;
  }
  &__link {
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    font-size: 10px;
    color: #7c8695;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #f9fafb !important;
    }
  }
  &__text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 2.5em;
  }
  &__date {
    display: block;
    margin-left: auto;
  }
}
