.divider-message {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  white-space: nowrap;
  margin: 3px 0px;

  &.margin-top-0{
    margin-top: 0;
  }

  .time {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #C0C0C0;
    z-index: 2;
    background-color: #FFFFFF;

    display: inline-block;
    padding: 0px 4px;
    text-transform: uppercase;
  }
  &::before,&::after {
    content: '';

    width: 100%;
    border-top: 1px solid rgba(192, 192, 192, 0.3);
    align-self: center;
  }

  &.unread{
    &::before,&::after {
      border-top: 1px solid #FF6F6F;
    }

    .time{
      color: #FF6F6F
    }
  }




  .new{
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;

    padding: 2px 4px;
    border-radius: 10px;
    background-color: #FF6F6F;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 39px;
  }


}