.l-layout {
	max-height: 100vh;
}
.l-main {
	margin-left: 280px;
	margin-top: 82px;
	@media screen and (max-width: 1439px) {
		margin-left: 70px;
	}
}

.main {
	display: flex;
	flex-direction: row;
}

.layout-wrapper {
	display: flex;
	height: calc(100vh - 82px);
	width: 100%;
	overflow: auto;
	&::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 6px #FFFFFF;
		background-color: #FFFFFF;
	}

	&::-webkit-scrollbar{
		width: 4px;
		background-color: #FFFFFF;
	}

	&::-webkit-scrollbar-thumb{
		background-color: #D9D9D9;
		border-radius: 9px;
	}

	&.show-message {
		width: calc(100% - 390px);
		transition: .25s;
		height: calc(100vh - 82px);
		overflow: auto;
	}
}

.content {
	flex: 1;
	transition: .3s;
	height: 100%;
	overflow: auto;
	&::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 6px #FFFFFF;
		background-color: #FFFFFF;
	}

	&::-webkit-scrollbar{
		width: 4px;
		background-color: #FFFFFF;
	}

	&::-webkit-scrollbar-thumb{
		background-color: #D9D9D9;
		border-radius: 9px;
	}
}
