@use "./base";
@use "./fonts";
@use "./checkbox";
@use "./textarea";
@use "./order-status";
@use "./spacing";
@use "./box";
@use "./empty";
@use "./icon";
.text-item {
	display: flex;
	&__name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0;
	}
}
.btn[disabled] {
	color: #fff;
	background-color: #cecece;
	border-color: #cecece;
}
.pointer-event {
	cursor: pointer;
}
.fw-600 {
	font-weight: 600;
}
.fs-14 {
	font-size: 0.875rem;
}
.fs-18 {
	font-size: 1.125rem;
}

input[type="email"],
input[type="password"],
input[type="text"] {
	&::placeholder {
		color: #cecece;
		font-family: var(--font-ubunty);
	}
}
.font-ubunty {
	font-family: var(--font-ubunty);
}
