.file-item {
	height: fit-content;
	&:not(:last-child) {
		margin-bottom: 10px;
	}
	&__link {
		display: flex;
		align-items: center;
		padding: 0 35px 0 22px;
		text-decoration: none;
		@media (max-width: 750px) {
			padding: 0 1rem 0 1rem;
		}
	}
	.icon {
		@media (max-width: 750px) {
			display: none;
		}
	}
	&__name {
		word-break: break-all;
		white-space: nowrap;
		color: #72777a;
	}
	&__wrap {
		flex: 1;
		display: flex;
		min-width: 0;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&__extention {
		min-width: 0;
		color: #72777a;
	}
	&__size {
		width: 30%;
		margin-left: auto;
		min-width: 90px;
		text-align: right;
		font-family: var(--font-ubunty);
		font-size: 12px;
	}
}
.text{
	&__danger {
		color: var(--color-red);
	}
	&__success {
		color: var(--color-green);
	}
	&__warning {
		color: var(--color-yellow);
	}
	&__primary {
		color: var(--color-blue);
	}
}
