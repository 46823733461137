body {
	font-size: 0.875rem;
	color: #72777a;
	background-color: #f9fafb;
	min-width: 1200px;
	font-family: "Ubunty", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;

	&::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 6px #FFFFFF;
		background-color: #FFFFFF;
	}

	&::-webkit-scrollbar{
		width: 4px;
		background-color: #FFFFFF;
	}

	&::-webkit-scrollbar-thumb{
		background-color: #D9D9D9;
		border-radius: 9px;
	}
}
html {
	@media (max-width: 375px) {
		font-size: 4.267vw;
	}
	--bg-blue: #e3f2fd !important;
	--color-blue: #2196f3 !important;

	--bg-green: #e8f5e9 !important;
	--color-green: #388e3c !important;

	--bg-red: #ffebee !important;
	--color-red: #f44336 !important;

	--bg-purple: #ede7f6 !important;
	--color-purple: #512da8 !important;

	--bg-yellow: #fffde7 !important;
	--color-yellow: #fbc02d !important;

	--font-ubunty: "Ubunty Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
