.extras-order-item {
  border-top: solid 1px rgba(0, 0, 0, 0.0625);
  padding: 12px 0;
  color: #212529;

  &__dt {
    width: 122px;
  }
  &__dd {
    flex: 1;
    min-width: 0;
    word-break: break-word;
    &--success{
      color: #4caf50;
    }
    &--warning{
      color: #ffeb3b;
    }
  }
}