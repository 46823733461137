.loading-message {

  &-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  display: flex;
  gap: 16px;
  width: 100%;

  .box {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #F4F4F4
  }

  .info{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;

  }

  .name {
    width: 30%;
    height: 18px;
    background: #F4F4F4;
    border-radius: 6px;
  }

  .msg {
    width: 100%;
    height: 28px;
    background: #F4F4F4;
    border-radius: 6px;
  }

  .skeleton {
    background-color: #e2e5e7;

    background-image:
            linear-gradient(
                            90deg,
                            rgba(#fff, 0),
                            rgba(#fff, 0.5),
                            rgba(#fff, 0)
            );
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
  }

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }



}