.c-table {
	table {
		min-width: 1000px;
	}
	th,
	td {
		padding: 0.75rem !important;
		border-top: 1px solid rgba(0, 0, 0, 0.0625);
		border-bottom: none;
		&.loading-spacing {
			padding: 1.25rem 0 !important;
			.loading-icon {
				font-size: 35px;
				opacity: 0.3;
			}
		}
	}
	th {
		font-family: "Ubunty Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
		text-transform: uppercase;
		font-size: 12px;
	}
	&__id {
		width: 72px;
	}
	&__status {
		width: 92px;
	}
	&__deadline {
		width: 167px;
	}
	&__urgency,
	&__size {
		width: 132px;
	}
}
.order-info {
	cursor: pointer;
	&:hover{
		background-color: #8080800d;
	}
	.text-success {
		color: #4caf50 !important;
	}

	&__id {
		font-weight: 600;
	}
	&__deadline {
		color: #d9534f;
	}
}
