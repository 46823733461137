.c-checkbox {
	&__input {
		opacity: 0;
		z-index: 1;
		&:checked + label {
			&::after {
				font-family: "FontAwesome";
				content: "\F00C";
				font-size: 13px;
				display: inline-block;
				position: absolute;
				width: 17px;
				height: 17px;
				text-align: center;
				font-size: 10px !important;
				line-height: 17px;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				margin-left: -12px;
				color: #fff;
				background-color: #0f9aee;
				border-color: #0f9aee;
				border-radius: 50%;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
			}
		}
	}
	&__label {
		vertical-align: middle;
		position: relative;
		padding-left: 17px;
		cursor: pointer;
		&::before {
			content: "";
			border-radius: 50%;
			display: inline-block;
			position: absolute;
			width: 17px;
			height: 17px;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			margin-left: -12px;
			border: 1px solid #e6eaf0;
			border-radius: 50%;
			background-color: #ffffff;
			transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
		}
	}
}
