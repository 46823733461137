.notification {
	position: relative;
	&__btn {
		height: 45px;
		width: 45px;
		text-align: center;
		line-height: 45px;
		position: relative;
		border: none;
		background: transparent;
	}
	&__total {
		position: absolute;
		right: 6px;
		top: 3px;
		background-color: #ff3c7e;
		border-radius: 50px;
		color: #fff;
		font-size: 10px;
		line-height: 1;
		padding: 3px 5.5px;
	}
	&__overview {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		padding: 20px;
		span {
			color: #acacac;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 10px;
		}
		a,
		button {
			padding: 0;
			border: none;
			text-decoration: none;
			background: transparent;
		}
	}
	&__content {
		position: absolute;
		right: 0;
		top: 110%;
		z-index: 999;
		padding: 0;
		margin: 0;
		width: 370px;
		box-shadow: 0 15px 20px rgb(0 0 0 / 20%);
		background-color: #fff;
		background-clip: padding-box;
		transform-origin: top right;
		transform: scale(0, 0);
		transition: transform 0.15s ease-out;
		border: 1px solid rgba(0, 0, 0, 0.15);
		&.show {
			transform: scale(1, 1);
		}
	}
	&__list {
		max-height: 400px;
		overflow: auto;
	}
	.btn-clear {
		color: blue;
	}
}
