.overview {
	display: flex;
	margin: 0 -10px 20px;
	flex-wrap: wrap;

	&__ttl {
		margin-bottom: 18px;
	}
	&__item {
		padding: 0 10px;
		flex: 1;
	}
	&__bg {
		padding: 20px;
		letter-spacing: 0.5px;
		border: 1px solid rgba(0, 0, 0, 0.0625);
	}
	&__bill {
		border-radius: 15px;
		font-weight: 600;
		line-height: 1;
		padding-top: 5px;
		padding-bottom: 5px;
		background-color: #ddd;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		-ms-border-radius: 15px;
		-o-border-radius: 15px;
		&--available {
			color: var(--color-blue);
			background-color: var(--bg-blue);
		}
		&--editing {
			color: var(--color-red);
			background-color: var(--bg-red);
		}
		&--preparing {
			color: var(--color-green);
			background-color: var(--bg-green);
		}
		&--completed {
			color: var(--color-purple);
			background-color: var(--bg-purple);
		}
	}
}
