.overview-order-item {
    border-top: solid 1px rgba(0, 0, 0, 0.0625);
    padding: 12px 0;
    color: #212529;

    &__dt {
        width: 122px;
    }
    &__dd {
        flex: 1;
        min-width: 0;
        word-break: break-word;
        &--success{
            color: #4caf50;
        }
        &--warning{
            color: #ffeb3b;
        }
    }
}
.empty {
    display: block;
    height: 22px;
    line-height: 22px;
    text-align: left;
    color: rgba(127, 35, 28, 0.3);
    font-style: italic;
}