.sign-in {
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	&__left {
		background: url(../../assets/images/login-bg.jpg) center center no-repeat;
		background-size: cover;
	}
	&__right {
		padding: 5rem 2.5rem;
	}
	&__ttl {
		margin-bottom: 40px;
	}
}
