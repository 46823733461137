.icon {
	display: inline-block;
	text-align: center;
	height: 32px;
	width: 32px;
	min-width: 0;
	line-height: 32px;
	font-size: 28px !important;
	color: #388e3c;
	opacity: 0.5;
	margin-right: 22px;
	&--blue {
		color: #1faae2;
	}
	&--small {
		width: 14px;
		height: 14px;
		text-align: center;
		line-height: 14px;
	}
	&--medium {
		width: 22px;
		height: 22px;
		text-align: center;
		line-height: 22px;
	}
}
