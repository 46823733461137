.sign-in {
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	&__left {
		background: url(../../assets/images/login-bg.jpg) center center no-repeat;
		background-size: cover;
	}
	&__right {
		padding: 5rem 2.5rem;
	}
	&__ttl {
		margin-bottom: 40px;
	}
	&__button {
		font-size: 0.875rem;
		font-family: "Ubunty Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
	}
}
