.tool-bar {
	position: relative;
	&__avatar {
		width: 2rem;
		height: 2rem;
		@media (max-width: 991px) {
			width: 3rem;
			height: 3rem;
		}
	}
	&__btn {
		padding: 15px 0;
		border: none;
	}
	&__list {
		button,
		a {
			color: #72777a;
			border: none;
			background: transparent;
			text-decoration: none;
			padding: 5px 15px;
			&:hover {
				background-color: #f9fafb !important;
				color: #72777a;
			}
		}
	}
	&__item ~ .tool-bar__item {
		border-top: 1px solid rgba(0, 0, 0, 0.0625);
	}

	&__list {
		position: absolute;
		top: 100%;
		right: 0;
		min-width: 10rem;
		padding: 0.5rem 0;
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 0.25rem;
		z-index: 1000;
		background-color: #fff;
		background-clip: padding-box;
		transform-origin: top right;
		transform: scale(0, 0);
		transition: transform 0.15s ease-out;
		&.show {
			transform: scale(1, 1);
		}
	}
}
