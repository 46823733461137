.top-nav {
	position: fixed;
	top: 0;
	left: 280px;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	height: 82px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
	z-index: 9999;
	@media screen and (max-width: 1439px) {
		left: 70px;
	}
	&__left {
		flex: 1;
	}
	&__right {
		display: flex;
		align-items: center;
		width: 390px;
		justify-content: flex-end;
	}
}
