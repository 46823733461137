.search-box {
	position: relative;
	.search{
		&__input {
			background: rgba(244, 244, 244, 1) !important;
			padding-left: 35px !important;
			&::placeholder {
				color: #868b90;
				font-size: 0.875rem;
			}
		}
		&__wrapp {
			position: relative;
		}
		&__icon {
			position: absolute;
			top: 50%;
			left: 10px;
			color: #cecece;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
		}
	}
	.clear-btn{
		position: absolute;
		top: 8%;
		border: none;
		right: 8px;
		background: transparent;
		color: #cecece;
		font-size: 18px;
	}
}

.tool_tip {
	background-color: #fce4e4;
	border: 1px solid #fcc2c3;
	border-radius: 7px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	position: absolute;
	right: 0;
	bottom: -40px;
	padding: 7px 11px 4px;
	&::before,
	&::after{
		content: '';
		border: 7px solid transparent;
		position: absolute;
		top: 10px;
	}
	&::before {
		border-bottom: 7px solid #fcc2c3;
		top: -15px;
	}
}
