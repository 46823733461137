.aside {
	max-width: 100%;

	@media (min-width: 991px) {
		position: sticky;
		top: 1rem;
	}
	@media (min-width: 992px) {
		width: 300px;
	}
	@media (min-width: 1430px) {
		width: 390px;
	}
	.pd-12 {
		padding: 12px;
	}
	&__apply-info {
		font-size: 10px;
	}
	&__deadline {
		background-color: #ddd;
		&--warning {
			background-color: #f44336;
		}
		&--info {
			background-color: #03a9f4;
		}
	}
	&__apply-comment {
		background-color: #fffbd8;
		display: block;
		max-height: 700px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	&__btn {
		width: 32px;
		height: 36px;
		color: #212529;
		background-color: #ffe715;
		border-color: #ffe608;
	}
	&__remark {
		&__textarea {
			font-family: var(--font-ubunty);
			&::placeholder {
				color: #cecece;
			}
			&:focus {
				outline: none;
			}
		}
	}
	&__upload {
		&__btn {
			color: #fff;
			position: relative;
		}
		&__input {
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
	}
	&__summary {
		border-top: 1px solid rgba(0, 0, 0, 0.0625);
		overflow-y: auto;
		overflow-x: hidden;
		padding: 20px 20px 0 20px;
	}
	.papers-item {
		display: flex;
		align-items: center;
		&__wrap {
			display: flex;
			flex: 1;
			min-width: 0;
		}
		&__name {
			min-width: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
