.paragraph {
	font-size: 14px;
	line-height: 1.5;
	&.active {
		.paragraph__inner {
			max-height: initial;
		}
		.overlay {
			&:before {
				display: none !important;
			}
		}
	}
	.paragraph__inner {
		max-height: 172px;
		overflow: hidden;
		padding-bottom: 30px;

		p {
			margin-bottom: 4px;
		}
	}
	@media (max-width: 640px) {
		position: relative;
		display: block;
		.order-info__data {
			padding-left: 0 !important;
		}
		.paragraph__content {
			margin-top: 8px;
		}
	}
	.paragraph__content {
		font-size: 14px;
		word-break: break-word;
		position: relative;
		&.overlay {
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				height: 45px;
				background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
				z-index: 2;
				left: 0;
				width: 100%;
			}
		}
	}
	.read-more {
		position: relative;
		text-decoration: none;
		padding-bottom: 2px;
		font-weight: 700;
		font-size: 12px;
		color: #1faae2;
		text-decoration: none;

		&:hover {
			&::before {
				display: none;
			}
		}
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			height: 1px;
			background-color: #4487ed;
		}
	}
}
