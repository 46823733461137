.order-preview {
	padding: 22px 0 0 22px;
	&__topic {
		font-weight: bold;
	}
	&__instructions {
		background-color: #e8f5e9;
		padding: 15px;
		border-radius: 3px;
	}
	&__file {
		padding: 22px 0 15px 0;
	}
	.btn {
		min-width: 112px;
		font-family: var(--font-ubunty);
		text-transform: uppercase;
	}
}
.file-item {
	padding-left: 22px;
	height: 22px;
	margin-bottom: 3px;
	&__ficon {
		margin-right: 10px;
	}
	&__fsize {
		font-size: 10px;
		display: inline-block;
		height: 22px;
		line-height: 22px;
		width: 42px;
		color: #1976d2;
	}
	&__fname {
		cursor: pointer;
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
.order-options {
	display: flex;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	margin-right: 3px;
	overflow: hidden;

	&__dd,
	&__dt {
		font-weight: 400;
		padding: 0 12px;
		border: 2px solid #ccc;
		text-align: center;
		font-size: 10px;
		min-width: 72px;
		font-family: var(--font-ubunty);
		margin-bottom: 0;
		height: 27px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__dt {
		background-color: #ddd;
		border-right: none;
		color: #fff;
		&:before {
			content: "#";
			color: #ffffff;
		}
	}
	&__dd {
		border-radius: 0 3px 3px 0;
		-webkit-border-radius: 0 3px 3px 0;
		-moz-border-radius: 0 3px 3px 0;
		-ms-border-radius: 0 3px 3px 0;
		-o-border-radius: 0 3px 3px 0;
	}
	&__level {
		dd {
			border-color: #50af50;
		}
		dt {
			border-color: #50af50;
			background-color: rgba(80, 175, 80, 0.8);
		}
	}
	&__citation {
		dd {
			border-color: #b028b0;
		}
		dt {
			border-color: #b028b0;
			background-color: rgba(176, 40, 176, 0.8);
		}
	}
	&__spacing {
		dd {
			border-color: #82a0dc;
		}
		dt {
			border-color: #82a0dc;
			background-color: rgba(130, 160, 220, 0.8);
		}
	}
	&__words {
		dd {
			border-color: #2896fa;
		}
		dt {
			border-color: #2896fa;
			background-color: rgba(40, 150, 250, 0.8);
		}
	}
}
