.side-nav {
	position: fixed;
	bottom: 0;
	top: 0;
	overflow: auto;
	transition: all 0.2s ease;
	width: 280px;
	z-index: 1000;
	background-color: #fff;
	border-right: 1px solid rgba(0, 0, 0, 0.0625);
	z-index: 9999;
	@media screen and (max-width: 1439px) {
		width: 70px;
		&:hover {
			width: 280px;
			.side-nav__logo {
				@media screen and (max-width: 1439px) {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
			.side-nav__ttl {
				@media screen and (max-width: 1439px) {
					display: initial;
				}
			}
			.side-nav__name {
				@media screen and (max-width: 1439px) {
					display: initial;
				}
			}
			.side-nav__number {
				@media screen and (max-width: 1439px) {
					right: 10px;
					margin-top: 0;
				}
			}
		}
	}
	&__logo {
		padding: 15px 20px 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
		@media screen and (max-width: 1439px) {
			padding-left: 10px;
			padding-right: 10px;
		}
		@media (max-width: 400px) {
			padding: 1rem;
		}
	}
	&__ttl {
		@media screen and (max-width: 1439px) {
			display: none;
			white-space: nowrap;
		}
	}
	&__name {
		font-weight: 500;
		font-size: 15px;
		@media screen and (max-width: 1439px) {
			display: none;
		}
	}
	&__icon {
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 17px;
	}
	&__link {
		padding: 5px 15px;
		color: #72777a;
		text-decoration: none;
		position: relative;
	}
	&__number {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 9px;
		background: #ff3c7e;
		color: #fff;
		display: block;
		padding: 0px 5px;
		font-size: 10px;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		@media screen and (max-width: 1439px) {
			right: 2px;
			margin-top: -13px;
		}
	}
	&__item {
		&.link-disable{
			cursor: not-allowed !important;
			.side-nav__link{
				pointer-events: none;
			}
		}
		&.active {
			background: #cccccc;
		}
	}
	&__list {
		@media (min-width: 992px) {
			margin-top: 30px;
		}
	}
}
